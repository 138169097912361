import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CancelButton, FieldGenerator } from '@silinfo/front-end-template';
import Autocomplete from '../../../../components/Form/Autocomplete';
import Form from '../../../../components/Form/Form';
import courseEnrollmentService from '../../../../services/courseManagement/courseEnrollment';
import courseListService from '../../../../services/courseManagement/courseList';
import { RootState } from '../../../../store';
import { Option } from '../../../../utils/AppConst';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { theme } from '../../../../utils/theme';
import WaitingListManagement from './WaitingListManagement';

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const initialValues = {
    tenant: '',
    student: '',
    campus: '',
    operation: '',
    studentToWaitingList: '',
};

export type TAddStudentButton = typeof initialValues;

export interface ICourseInfo {
    maxHeadCount: number;
    headCount: number;
    headCountWaiting: number;
}

export default function AddStudentButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    courseInfo,
    onStudentAdded,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    courseInfo: ICourseInfo;
    onStudentAdded: () => void;
}) {
    const [tenantOptions, setTenantOptions] = useState<Option[]>([]);
    const [currentTenant, setCurrentTenant] = useState<Option>({ value: '', label: '' });
    const { courseId } = useParams() as { courseId: string };
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const [open, setOpen] = useState(false);
    const [waitingListFilled, setWaitingListFilled] = useState(false);
    const { count } = useSelector((state: RootState) => state.loading);

    const classes = useStyles();

    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    useEffect(() => {
        if (courseId) {
            courseListService.tenants(courseId.toString()).then((res) => {
                setTenantOptions([...res.data.tenants]);
                const currentTenantValue = res.data.currentTenant;

                const matchingTenant = res.data.tenants.find(
                    (tenant: { value: string }) => tenant.value === currentTenantValue,
                );
                setCurrentTenant(matchingTenant ?? { value: '', label: '' });
            });
        }
    }, [courseId]);

    const handleWaitingListValueChange = useCallback((value: boolean) => {
        setWaitingListFilled(value);
    }, []);

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <div>
                    <DialogContent>
                        <Form
                            hideButtons
                            fields={[]}
                            urlParam={'courseId'}
                            onSubmit={(form: IForm) => {
                                return courseEnrollmentService.enroll(courseId, courseTenant, form).then(() => {
                                    setTableLoading((prev) => !prev);
                                    onStudentAdded();
                                    handleClose();
                                });
                            }}
                            submitTransformData={(form) =>
                                Object.entries(form).reduce(
                                    (prev, [key, value]: [string, unknown]) => ({
                                        ...prev,
                                        [key]: Array.isArray(value)
                                            ? value.map((elem) => elem?.value || elem)
                                            : (value as Option)?.value || value,
                                    }),
                                    {},
                                ) as IForm
                            }
                            initialValues={{ ...initialValues, tenant: currentTenant?.value || '' }}
                            {...(objectId ? {} : {})}
                        >
                            {(formikProps) => {
                                const isWaitingListRequired =
                                    !!formikProps.values.student &&
                                    (courseInfo.headCountWaiting > 0 ||
                                        courseInfo.maxHeadCount === courseInfo.headCount);

                                return (
                                    <>
                                        <Grid item xs={12}>
                                            {FieldGenerator({
                                                name: 'tenant',
                                                label: 'Tenant',
                                                type: 'select',
                                                fieldType: 'base',
                                                format: { xs: 12 },
                                                options: tenantOptions,
                                                props: {
                                                    disableClearable: true,
                                                    renderOption: (
                                                        props: React.HTMLAttributes<HTMLLIElement>,
                                                        option: Option,
                                                    ) => <li {...props}>{option.label}</li>,
                                                    getOptionLabel: (option: Option) => option.label || '',
                                                    value: currentTenant?.value,
                                                    disabled: true,
                                                },
                                                ...formikProps,
                                            })}
                                            <Autocomplete
                                                getter={(term) => {
                                                    return courseListService.centralStudentSearch(
                                                        term,
                                                        currentTenant?.value,
                                                    );
                                                }}
                                                textFieldProps={{ label: 'Hallgató' }}
                                                errorMessage={formikProps.errors.student as string}
                                                autocompleteProps={{
                                                    value: formikProps.values.student || '',
                                                    onChange: (_, v) => formikProps.setFieldValue('student', v),
                                                    disabled: !currentTenant,
                                                }}
                                                createOptions={(users: { value: number; label: string }[]) =>
                                                    users.map((user) => ({
                                                        value: user.value.toString(),
                                                        label: user.label,
                                                    }))
                                                }
                                            />
                                        </Grid>

                                        {isWaitingListRequired && (
                                            <WaitingListManagement
                                                {...formikProps}
                                                courseId={courseId}
                                                courseInfo={courseInfo}
                                                onWaitingListValueChange={handleWaitingListValueChange}
                                            />
                                        )}

                                        <Grid item container spacing={2} justifyContent="flex-end">
                                            <Grid item>
                                                <LoadingButton
                                                    loading={count > 0}
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isWaitingListRequired && !waitingListFilled}
                                                >
                                                    {submitBtnTitle}
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item>
                                                <CancelButton onClick={handleClose}>Mégsem</CancelButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            }}
                        </Form>
                    </DialogContent>
                </div>
            </DialogBasic>
        </>
    );
}
