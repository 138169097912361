import { Input } from '@silinfo/front-end-template';
import courseEvaluationReportService from '../../../services/courseManagement/courseEvaluationReport';
import { endpoints } from '../../../utils/endPoints';
import { CourseEvaluationView } from './types';

const courseFields = (semesterId: string): Input[] => [
    {
        name: 'course',
        label: 'Kurzus neve',
        type: 'autocomplete',
        multiple: true,
        getter: courseEvaluationReportService.courseSearch(semesterId),
        format: {
            xs: 12,
        },
    },
];

const studentFields = (semesterId: string): Input[] => [
    {
        name: 'student',
        label: 'Hallgató neve',
        type: 'autocomplete',
        multiple: true,
        getter: courseEvaluationReportService.studentSearch(semesterId),
        format: {
            xs: 12,
        },
    },
    {
        name: 'course',
        label: 'Kurzus',
        type: 'autocomplete',
        getter: courseEvaluationReportService.courseSearch(semesterId),
        format: {
            xs: 12,
        },
    },
];

const trainingFields = (view: string): Input[] =>
    view === 'course'
        ? [
              {
                  name: 'trainingProgram',
                  label: 'Képzési program',
                  type: 'backendSelect',
                  multiple: true,
                  justValue: true,
                  url: endpoints.masterData.trainingPrograms.optionsForFilterStudentExternal,
                  responseKey: '',
                  format: {
                      xs: 12,
                      sm: 6,
                  },
              },
          ]
        : [
              {
                  name: 'trainingLevel',
                  label: 'Képzési szint',
                  type: 'backendSelect',
                  multiple: true,
                  justValue: true,
                  url: endpoints.masterData.trainingLevels.trainingLevels,
                  responseKey: '',
                  format: {
                      xs: 12,
                      sm: 6,
                  },
              },
              {
                  name: 'trainingProgram',
                  label: 'Képzési program',
                  type: 'backendSelect',
                  multiple: true,
                  justValue: true,
                  url: endpoints.masterData.trainingPrograms.optionsForFilterStudent,
                  responseKey: '',
                  format: {
                      xs: 12,
                      sm: 6,
                  },
              },
              {
                  name: 'collegeYear',
                  label: 'Évfolyam',
                  type: 'backendSelect',
                  multiple: true,
                  justValue: true,
                  url: endpoints.masterData.collegeYears.options,
                  responseKey: '',
                  format: {
                      xs: 12,
                      sm: 6,
                  },
              },
          ];

const filterFields = (semesterId: string, view: CourseEvaluationView): Input[] => [
    ...(view === 'course' ? courseFields(semesterId) : studentFields(semesterId)),
    {
        name: 'campus',
        label: 'Campus',
        type: 'backendSelect',
        justValue: true,
        multiple: true,
        url: endpoints.courseManagement.courseList.listCampusesWithoutTenant,
        responseKey: '',
        format: {
            xs: 12,
            sm: 6,
        },
    },
    ...trainingFields(view),
    {
        name: 'anonymous',
        label: 'Anonim',
        type: 'multiselect',
        options: [
            { value: '1', label: 'Igen' },
            { value: '0', label: 'Nem' },
        ],
        format: {
            xs: 12,
            md: 6,
        },
        takeUpEntireRow: true,
    },
    {
        name: 'filled',
        label: 'Teljesen kitöltött',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'notFilled',
        label: 'Csak kitöltetlen',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'declined',
        label: 'Csak elutasított',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'expired',
        label: 'Csak lejárt',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
];

const filterFieldsStudents = (semesterId: string, view: CourseEvaluationView): Input[] => [
    ...(view === 'course' ? courseFields(semesterId) : studentFields(semesterId)),
    {
        name: 'campus',
        label: 'Campus',
        type: 'backendSelect',
        multiple: true,
        justValue: true,
        url: endpoints.courseManagement.courseList.listCampusesInternal,
        responseKey: '',
        format: {
            xs: 12,
            sm: 6,
        },
    },
    ...trainingFields(view),
    // {
    //     name: 'anonymous',
    //     label: 'Anonim',
    //     type: 'multiselect',
    //     options: [
    //         { value: '1', label: 'Igen' },
    //         { value: '0', label: 'Nem' },
    //     ],
    //     format: {
    //         xs: 12,
    //         md: 6,
    //     },
    //     takeUpEntireRow: true,
    // },
    {
        name: 'filled',
        label: 'Teljesen kitöltött',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'notFilled',
        label: 'Csak kitöltetlen',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'declined',
        label: 'Csak elutasított',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
    {
        name: 'expired',
        label: 'Csak lejárt',
        type: 'checkbox',
        format: {
            xs: 12,
            sm: 6,
            md: 3,
        },
    },
];

export { filterFields, filterFieldsStudents };
