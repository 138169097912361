export const endpoints = {
    me: process.env.REACT_APP_BACKEND_URL + '/me',
    accessTree: process.env.REACT_APP_BACKEND_URL + '/access-tree',
    backendVersion: process.env.REACT_APP_BACKEND_URL + '/version',
    changeEmailGenCode: process.env.REACT_APP_BACKEND_URL + '/student/change-email/generate-code',
    changeEmailChange: process.env.REACT_APP_BACKEND_URL + '/student/change-email',
    changePassword: process.env.REACT_APP_BACKEND_URL + '/users/change-password',
    systemMessages: process.env.REACT_APP_BACKEND_URL + '/user-notifications/system-messages',
    seen: process.env.REACT_APP_BACKEND_URL + '/user-notifications/seen',
    unseen: process.env.REACT_APP_BACKEND_URL + '/user-notifications/unseen',
    allSeen: process.env.REACT_APP_BACKEND_URL + '/user-notifications/all-seen',
    allUnSeen: process.env.REACT_APP_BACKEND_URL + '/user-notifications/all-unseen',
    publicFileDownload: process.env.REACT_APP_BACKEND_URL + '/file-download-public',
    publicFileUpload: process.env.REACT_APP_BACKEND_URL + '/file-upload-public',
    currentTenantAll: process.env.REACT_APP_BACKEND_URL + '/current-tenant-all',
    tenantList: process.env.REACT_APP_BACKEND_URL + '/tenant-list',
    test: {
        dummy: process.env.REACT_APP_BACKEND_DUMMY_URL + '/test/status.php',
        symfony: process.env.REACT_APP_BACKEND_URL + '/status/database',
    },
    countries: {
        main: process.env.REACT_APP_BACKEND_URL + '/countries',
        countriesWithCampuses: process.env.REACT_APP_BACKEND_URL + '/countries-with-campuses',
        countriesWithUniversities: process.env.REACT_APP_BACKEND_URL + '/countries-with-universities',
    },
    logout: process.env.REACT_APP_AUTH_URL + '/logout',
    logoutLog: process.env.REACT_APP_BACKEND_URL + '/user/update/logout-log',
    runCommand: {
        command1: process.env.REACT_APP_BACKEND_URL + '/run/language-exam-requirement-non-compliance-fee',
    },
    googleMapsGeocode: process.env.REACT_APP_BACKEND_URL + '/google-maps/geocode',
    students: {
        profile: {
            main: process.env.REACT_APP_BACKEND_URL + '/profile',
            studies: {
                languageExams: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/language-exams',
                curriculumVitae: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/curriculum-vitae',
                certification: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/certificate',
                foreignScholarship: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/foreign-scholarship',
                internship: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/internship',
                competition: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/competition',
                workplace: process.env.REACT_APP_BACKEND_URL + '/profile/details/studies/workplace',
                previousStudies: process.env.REACT_APP_BACKEND_URL + '/students/completed-training-program',
            },
            baseData: {
                savePrimaryForm: process.env.REACT_APP_BACKEND_URL + '/profile/base-data/primary/update',
                saveSecondaryForm: process.env.REACT_APP_BACKEND_URL + '/profile/base-data/secondary/update',
                saveProfilePicture: process.env.REACT_APP_BACKEND_URL + '/student/profile-image',
                saveActualUniversityStudies: process.env.REACT_APP_BACKEND_URL + '/profile/actual-university-studies',
                savePhone: process.env.REACT_APP_BACKEND_URL + '/profile/base-data/phone/update',
                saveAddress: process.env.REACT_APP_BACKEND_URL + '/profile/base-data/address/update',
            },
        },
        courseEnrollment: {
            isEnrolled: process.env.REACT_APP_BACKEND_URL + '/profile/is-enrolled-current-semester',
            coursesList: process.env.REACT_APP_BACKEND_URL + '/course-enrollment/courses',
            main: process.env.REACT_APP_BACKEND_URL + '/course-enrollments',
            enrollment: process.env.REACT_APP_BACKEND_URL + '/course-enrollment',
            dismissal: process.env.REACT_APP_BACKEND_URL + '/course-dismissal',
            getAllByCourse: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/course-enrollments-by-course',
            getAllByCoursePaginated: process.env.REACT_APP_BACKEND_URL + '/course-enrollments/{courseId}',
            getEnrolledStudentsNames:
                process.env.REACT_APP_BACKEND_URL +
                '/course-enrollment/course/{id}/{courseTenant}/enrolled-students-names',
            currentSemesterInfo: process.env.REACT_APP_BACKEND_URL + '/current-semester-info',
            request: {
                enrollment: process.env.REACT_APP_BACKEND_URL + '/request/course-enrollment',
                prerequisite: process.env.REACT_APP_BACKEND_URL + '/request/prerequisite/{courseId}',
                dismissal: process.env.REACT_APP_BACKEND_URL + '/request/course-dismissal',
                changeActualStatus: process.env.REACT_APP_BACKEND_URL + '/request/change-actual-status',
                changeSemesterStatus: process.env.REACT_APP_BACKEND_URL + '/request/change-semester-status',
            },
            export: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/course-enrollments/initiate-export',
                check: process.env.REACT_APP_BACKEND_URL + '/course-enrollments/check-export/{id}',
                download: process.env.REACT_APP_BACKEND_URL + '/course-enrollments/download-export/{id}',
            },
        },
        requirements: {
            semesters: process.env.REACT_APP_BACKEND_URL + '/student/requirements/semesters',
            programs: process.env.REACT_APP_BACKEND_URL + '/student/requirements/programs',
            courses: process.env.REACT_APP_BACKEND_URL + '/student/semesters/{semesterId}/courses',
            performance(id: string) {
                return process.env.REACT_APP_BACKEND_URL + '/student/requirements/' + id + '/semester';
            },
            courseTypes(id: string) {
                return process.env.REACT_APP_BACKEND_URL + '/student/requirements/' + id + '/semester/course-type';
            },
            progress: process.env.REACT_APP_BACKEND_URL + '/student/requirements/training-program',
            mandatoryCourses: process.env.REACT_APP_BACKEND_URL + '/student/requirements/training-program/course-type',
            languageExams: process.env.REACT_APP_BACKEND_URL + '/student/requirements/training-level/lang-requirements',
            mandatoryCourseList:
                process.env.REACT_APP_BACKEND_URL + '/student/requirements/training-program/required-courses',
            courseTypesByProgram:
                process.env.REACT_APP_BACKEND_URL + '/student/requirements/training-program/course-type-detailed',
        },
        finances: {
            main: process.env.REACT_APP_BACKEND_URL + '/student/fees',
            scholarships: {
                measurements: process.env.REACT_APP_BACKEND_URL + '/student/scholarship/measurements',
                paid: process.env.REACT_APP_BACKEND_URL + '/student/scholarship/paid',
            },
        },
        calendar: {
            main: process.env.REACT_APP_BACKEND_URL + '/students/course-events',
        },
        processes: {
            listOpen: process.env.REACT_APP_BACKEND_URL + '/request/list/open',
            listClosed: process.env.REACT_APP_BACKEND_URL + '/request/list/closed',
            getApproval: process.env.REACT_APP_BACKEND_URL + '/student/approvals/{id}',
            getLanguageExam: process.env.REACT_APP_BACKEND_URL + '/student/language-exam-report/{id}',
        },
        selfEvaluation: {
            main: process.env.REACT_APP_BACKEND_URL + '/student/self-evaluation',
            form: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/student/self-evaluation/semesters/${id}/fill-questionnaire`,
            semesters: process.env.REACT_APP_BACKEND_URL + '/student/self-evaluation/semesters',
            semester: (id: string) => process.env.REACT_APP_BACKEND_URL + `/student/self-evaluation/semesters/${id}`,
            uploadUniversityIndex: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/student/self-evaluation/semesters/${id}/university-index`,
            removeUniversityIndex: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/student/self-evaluation/semesters/${id}/university-index`,
            pdf: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/student/self-evaluation/semesters/${id}/completion-row-for-print`,
            summary: (id: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/student/self-evaluation/semesters/${id}/fill-questionnaire-summary`,
            previousSummary: (id: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/student/self-evaluation/semesters/${id}/previous-summary-document`,
        },
        questionnaires: {
            main: (courseId: number) =>
                process.env.REACT_APP_BACKEND_URL + `/student/course-evaluation/course/${courseId}/fill-questionnaire`,
            list: process.env.REACT_APP_BACKEND_URL + '/student/course-evaluation/list',
        },
        document: {
            list: process.env.REACT_APP_BACKEND_URL + '/student/documents',
            download: process.env.REACT_APP_BACKEND_URL + '/student/documents/{id}/download',
        },
    },
    masterData: {
        courses: {
            courseTypes: {
                main: process.env.REACT_APP_BACKEND_URL + '/course-types',
                studentEnrollmentFilterOptions:
                    process.env.REACT_APP_BACKEND_URL + '/course-enrollment/options/course-type',
            },
            campuses: {
                studentEnrollmentFilterOptions: process.env.REACT_APP_BACKEND_URL + '/course-enrollment/options/campus',
            },
        },
        users: {
            main: process.env.REACT_APP_BACKEND_URL + '/users',
            getByEmail: process.env.REACT_APP_BACKEND_URL + '/users/get-by-email',
            sendActivationEmail: process.env.REACT_APP_BACKEND_URL + '/users/:userId/send-activation-email',
            forgotPasswordToken: process.env.REACT_APP_BACKEND_URL + '/users/{id}/send-forgot-password-token-email',
            campuses: process.env.REACT_APP_BACKEND_URL + '/users/campuses',
            mccId: process.env.REACT_APP_BACKEND_URL + '/users/mccid',
            trainingPrograms: process.env.REACT_APP_BACKEND_URL + '/users/training-programs',
            roles: process.env.REACT_APP_BACKEND_URL + '/users/access-roles',
            get2: process.env.REACT_APP_BACKEND_URL + '/users/get/{id}',
            profilePicture: process.env.REACT_APP_BACKEND_URL + '/file-download',
            getTrainingProgramByUserId: process.env.REACT_APP_BACKEND_URL + '/users/:userId/training-program',
            userSearch: process.env.REACT_APP_BACKEND_URL + '/user-search',
            userLoginInAs: process.env.REACT_APP_BACKEND_URL + '/login-in-as',
            archive: process.env.REACT_APP_BACKEND_URL + '/users/archive/{id}',
            getCentralUser: process.env.REACT_APP_BACKEND_URL + '/users/data/get-central',
        },
        students: {
            universities: process.env.REACT_APP_BACKEND_URL + '/students/universities',
            enrolledSemestersByStudent: process.env.REACT_APP_BACKEND_URL + '/students/{studentId}/enrolled-semesters',
            completedTrainingProgramByStudent:
                process.env.REACT_APP_BACKEND_URL + '/students/{studentId}/completed-training-program',
            countries: {
                main: process.env.REACT_APP_BACKEND_URL + '/students/countries',
            },
            actualUniversityStudies: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/actual-university-studies/list?student={studentId}',
                foreignUniversity: {
                    list: process.env.REACT_APP_BACKEND_URL + '/student/foreign-universities',
                },
            },
            languageExams: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/{student}/details/studies/language-exams/list?student={studentId}',
            },
            curriculumVitae: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/curriculum-vitae/list?student={studentId}',
            },
            certification: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/certificate/list?student={studentId}',
                institute: process.env.REACT_APP_BACKEND_URL + '/student/certificate/institute',
                institutes: process.env.REACT_APP_BACKEND_URL + '/student/certificate/institutes',
            },
            foreignScholarship: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/foreign-scholarship/list?student={studentId}',
            },
            internship: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/internship/list?student={studentId}',
            },
            competition: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/competition/list?student={studentId}',
            },
            workplace: {
                main:
                    process.env.REACT_APP_BACKEND_URL +
                    '/md/student/details/studies/workplace/list?student={studentId}',
            },
            scholarship: {
                main: process.env.REACT_APP_BACKEND_URL + '/student/{studentId}/scholarship/list',
                schedule: process.env.REACT_APP_BACKEND_URL + '/student/{studentId}/scholarship/schedule/{semesterId}',
                export: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/student/{studentId}/scholarship/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/student/{studentId}/scholarship/check-export/{id}',
                    download:
                        process.env.REACT_APP_BACKEND_URL + '/student/{studentId}/scholarship/download-export/{id}',
                },
            },
            main: process.env.REACT_APP_BACKEND_URL + '/students',
            detailed: process.env.REACT_APP_BACKEND_URL + '/students/{studentId}/detailed',
            administrationFees: process.env.REACT_APP_BACKEND_URL + '/finance/student-fee-processes',
            uniqueAdministrationFee: process.env.REACT_APP_BACKEND_URL + '/student-fee-processes/{id}',
            listAdministrationFeeTypes: process.env.REACT_APP_BACKEND_URL + '/finance/list-administration-fee-types',
            administrationFee: process.env.REACT_APP_BACKEND_URL + '/students/administration-fees/{id}',
            findByEmail: process.env.REACT_APP_BACKEND_URL + '/students/find',
            addAdministrationFee: process.env.REACT_APP_BACKEND_URL + '/students/add-administration-fee',
            deleteAdministrationFee:
                process.env.REACT_APP_BACKEND_URL + '/administration-fee-vals/{feeId}/students/{studentId}/remove',
            actualStatuses: process.env.REACT_APP_BACKEND_URL + '/students/actual-statuses',
            semesterStatuses: process.env.REACT_APP_BACKEND_URL + '/students/semester-statuses',
            nationalities: process.env.REACT_APP_BACKEND_URL + '/students/nationalities',
            listFieldRules: process.env.REACT_APP_BACKEND_URL + '/students/detailed/field-rules',
            trainingForms: process.env.REACT_APP_BACKEND_URL + '/students/training-forms',
            financeForms: process.env.REACT_APP_BACKEND_URL + '/students/finance-forms',
            legalRelationships: process.env.REACT_APP_BACKEND_URL + '/students/legal-relationships',
            campuses: process.env.REACT_APP_BACKEND_URL + '/students/campuses',
            trainingLevels: process.env.REACT_APP_BACKEND_URL + '/students/training-levels',
            trainingPrograms: process.env.REACT_APP_BACKEND_URL + '/students/training-programs',
            collegeYears: process.env.REACT_APP_BACKEND_URL + '/students/college-years',
            searchByTerm: process.env.REACT_APP_BACKEND_URL + '/students/search-by-term',
            uniqueFeeAssign: process.env.REACT_APP_BACKEND_URL + '/students/add-unique-administration-fee',
            export: {
                detailed: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/students/detailed/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/students/detailed/check-export/{id}',
                    download: process.env.REACT_APP_BACKEND_URL + '/students/detailed/download-export/{id}',
                },
                furtherStudies: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/students/further-studies/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/students/further-studies/check-export/{id}',
                    download: process.env.REACT_APP_BACKEND_URL + '/students/further-studies/download-export/{id}',
                },
            },
            processes: {
                ongoing: process.env.REACT_APP_BACKEND_URL + '/student-process/list/{id}/ongoing',
                done: process.env.REACT_APP_BACKEND_URL + '/student-process/list/{id}/done',
                getEnrollment: process.env.REACT_APP_BACKEND_URL + '/student-process/get/enrollment/{id}',
                getStudentAllocationPreference:
                    process.env.REACT_APP_BACKEND_URL + '/student-process/get/student-allocation-preference/{id}',
                getRelocation: process.env.REACT_APP_BACKEND_URL + '/student-process/get/relocation/{id}',
                getTermination: process.env.REACT_APP_BACKEND_URL + '/student-process/get/termination/{id}',
                getMoveOut: process.env.REACT_APP_BACKEND_URL + '/student-process/get/move-out/{id}',
            },
            trainingProgramCompletion: {
                main: (id: string | number) =>
                    process.env.REACT_APP_BACKEND_URL + '/student/training-programs/completions/' + id,
                recalc: (id: string | number) =>
                    process.env.REACT_APP_BACKEND_URL + '/student/training-programs/completions/' + id + '/recalc',
                requiredCourseInfo: (id: string | number) =>
                    process.env.REACT_APP_BACKEND_URL +
                    '/student/training-programs/completions/required-course-info/' +
                    id,
                langInfo: (id: string | number) =>
                    process.env.REACT_APP_BACKEND_URL + '/student/training-programs/completions/lang-info/' + id,
                pdf: (id: string | number, completionId: string | number) =>
                    process.env.REACT_APP_BACKEND_URL +
                    `/administration/training-completions/${id}/row-for-print/${completionId}`,
                courseTypeInfo: (student: string | number, semester: string | number) =>
                    process.env.REACT_APP_BACKEND_URL +
                    `/student/training-programs/completions/course-type-detailed/${student}/${semester}`,
            },
        },
        universities: {
            main: process.env.REACT_APP_BACKEND_URL + '/universities',
        },
        collegeYears: {
            options: process.env.REACT_APP_BACKEND_URL + '/college-year-options',
            forSelectNew: process.env.REACT_APP_BACKEND_URL + '/college-year-options/for-select/new',
            forSelectTrainingProgram:
                process.env.REACT_APP_BACKEND_URL +
                '/college-year-options/for-select/training-program/{trainingProgramId}',
            byTrainingProgram: (trainingProgram: string) =>
                process.env.REACT_APP_BACKEND_URL +
                '/courses/tenant-college-years' +
                '?trainingProgram=' +
                trainingProgram,
        },
        trainingLevels: {
            main: process.env.REACT_APP_BACKEND_URL + '/training-levels',
            trainingPrograms: process.env.REACT_APP_BACKEND_URL + 'training-levels/{id}/training-programs',
            trainingLevels: process.env.REACT_APP_BACKEND_URL + '/training-level-options',
            archive: process.env.REACT_APP_BACKEND_URL + '/training-level/{levelId}/archive',
            forStudent: process.env.REACT_APP_BACKEND_URL + '/training-level-options/for-select/student/{studentId}',
            trainingProgramsForStudent:
                process.env.REACT_APP_BACKEND_URL +
                '/training-program-options/for-select/training-level/{trainingLevelId}/student/{studentId}',
            requirementExport: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/training-levels/export-requirements/initiate-export',
                check: process.env.REACT_APP_BACKEND_URL + '/training-levels/export-requirements/check-export/{id}',
                download:
                    process.env.REACT_APP_BACKEND_URL + '/training-levels/export-requirements/download-export/{id}',
            },
            byTenant: (tenantId: string) =>
                process.env.REACT_APP_BACKEND_URL + '/courses/tenant-training-levels' + '?tenant=' + tenantId,
        },
        languageExamRequirements: {
            main: process.env.REACT_APP_BACKEND_URL + '/language-exam-requirements',
            filterByTrainingLevel:
                process.env.REACT_APP_BACKEND_URL + '/training-levels/{id}/language-exam-requirements',
            languages: process.env.REACT_APP_BACKEND_URL + '/language-exam-requirements/languages',
            categories: process.env.REACT_APP_BACKEND_URL + '/language-exam-requirements/categories',
            levels: process.env.REACT_APP_BACKEND_URL + '/language-exam-requirements/levels',
            check: process.env.REACT_APP_BACKEND_URL + '/administration/language-exam-completions/check',
            checkFine: process.env.REACT_APP_BACKEND_URL + '/administration/language-exam-completions/fine',
        },
        trainingPrograms: {
            main: process.env.REACT_APP_BACKEND_URL + '/training-programs',
            options: process.env.REACT_APP_BACKEND_URL + '/training-programs-options',
            optionsForFilterStudent: process.env.REACT_APP_BACKEND_URL + '/training-program-options/for-filter/student',
            optionsForFilterStudentExternal:
                process.env.REACT_APP_BACKEND_URL +
                '/course-management/evaluation-report/training-program-options/for-filter/external',
            optionsByTrainingLevel: process.env.REACT_APP_BACKEND_URL + '/training-program-options/{levelId}',
            filter: process.env.REACT_APP_BACKEND_URL + '/training-levels/{levelId}/training-programs',
            collegeYears: process.env.REACT_APP_BACKEND_URL + '/college-years',
            archive: process.env.REACT_APP_BACKEND_URL + '/training-programs/{programId}/archive',
            fetchCollegeYears: process.env.REACT_APP_BACKEND_URL + '/training-programs/{programId}/college-years', //TODO: ezt törölni a végén
            fetchCollegeYearsByTrainingProgramAndStudent:
                process.env.REACT_APP_BACKEND_URL +
                '/college-year-options/for-select/training-program/{programId}/student/{studentId}',
            collegeYearsByLvlProgram:
                process.env.REACT_APP_BACKEND_URL +
                '/training-levels/{levelId}/training-programs/{programId}/college-years',
            requirements: {
                campuses:
                    process.env.REACT_APP_BACKEND_URL +
                    '/training-levels/{levelId}/training-programs/{programId}/requirements/campuses',
                campus:
                    process.env.REACT_APP_BACKEND_URL +
                    '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}',
                getBase:
                    process.env.REACT_APP_BACKEND_URL +
                    '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/base',
                saveBase:
                    process.env.REACT_APP_BACKEND_URL +
                    '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/base',
                courseType: {
                    list:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-types',
                    update:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-types',
                    show:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-types/{id}',
                    courseTypes:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-types/types',
                },
                courseReferenceCode: {
                    list:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-reference-codes',
                    refCodeSearch:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-reference-codes/ref-code-search',
                    create:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-reference-codes',
                    delete:
                        process.env.REACT_APP_BACKEND_URL +
                        '/training-levels/{levelId}/training-programs/{programId}/requirements/campus/{campusId}/course-reference-codes/{id}',
                },
            },
            byTenant: (tenant: string, trainingLevel: string) =>
                process.env.REACT_APP_BACKEND_URL +
                '/courses/tenant-training-programs' +
                '?tenant=' +
                tenant +
                '&trainingLevel=' +
                trainingLevel,
        },
        campuses: {
            main: process.env.REACT_APP_BACKEND_URL + '/campuses',
            sync: process.env.REACT_APP_BACKEND_URL + '/mcc-event-sync',
        },
        buildings: {
            main: process.env.REACT_APP_BACKEND_URL + '/buildings',
            filter: process.env.REACT_APP_BACKEND_URL + '/campuses/{campusId}/buildings',
        },
        floors: {
            main: process.env.REACT_APP_BACKEND_URL + '/floors',
            filter: process.env.REACT_APP_BACKEND_URL + '/campuses/{campusId}/buildings/{buildingId}/floors',
            fetch: process.env.REACT_APP_BACKEND_URL + '/buildings/{buildingId}/floors',
        },
        rooms: {
            main: process.env.REACT_APP_BACKEND_URL + '/rooms',
            fetch: process.env.REACT_APP_BACKEND_URL + '/floors/{floorId}/rooms',
            fetchClassRooms: process.env.REACT_APP_BACKEND_URL + '/floors/{floorId}/classrooms',
            fetchDormRooms: process.env.REACT_APP_BACKEND_URL + '/floors/{floorId}/dorm-rooms',
            filter:
                process.env.REACT_APP_BACKEND_URL +
                '/campuses/{campusId}/buildings/{buildingId}/floors/{floorId}/rooms',
        },
        items: {
            main: process.env.REACT_APP_BACKEND_URL + '/items',
            filter: process.env.REACT_APP_BACKEND_URL + '/rooms/{roomId}/items',
        },
        semesters: {
            main: process.env.REACT_APP_BACKEND_URL + '/semesters',
            deleteHoliday: process.env.REACT_APP_BACKEND_URL + '/holidays',
            check: (semesterId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/semesters/${semesterId}/close/check-requirements/start`,
            close: (semesterId: string) => process.env.REACT_APP_BACKEND_URL + `/semesters/${semesterId}/close/start`,
            checkClosingStatus: (semesterId: string, logId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/semesters/${semesterId}/close/info/${logId}`,
            getCourseList: (semesterId: string, logId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/semesters/${semesterId}/close/check-requirements/get-courses/${logId}`,
            getStudentList: (semesterId: string, logId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/semesters/${semesterId}/close/check-requirements/get-students/${logId}`,
        },
        finance: {
            administrationFee: {
                feesByTypes: process.env.REACT_APP_BACKEND_URL + '/finance/fees-by-types',
                list: process.env.REACT_APP_BACKEND_URL + '/finance/administration-fees',
                main: process.env.REACT_APP_BACKEND_URL + '/finance-administration-fees',
                semestersWithGivenAdministrationFee: process.env.REACT_APP_BACKEND_URL + '/finance/semesters',
                getProcesses: process.env.REACT_APP_BACKEND_URL + '/student/fee-processes',
            },
            administrationFeeVal: {
                list:
                    process.env.REACT_APP_BACKEND_URL +
                    '/finance-administration-fees/{feeId}/finance-administration-fee-vals',
                main: process.env.REACT_APP_BACKEND_URL + '/finance-administration-fee-vals',
                semesters: process.env.REACT_APP_BACKEND_URL + '/finance/{feeId}/semesters',
                administrationFeeValBySemesterAndAdministrationFee:
                    process.env.REACT_APP_BACKEND_URL +
                    '/administration-fees/{id}/semesters/{semesterId}/administration-fee-val',
                listPossibleStudentsForAdministrationFeeValue:
                    process.env.REACT_APP_BACKEND_URL + '/administration-fee-vals/{feeId}/possible-students',
            },
        },
    },
    administration: {
        settings: {
            main: process.env.REACT_APP_BACKEND_URL + '/settings',
            changeSettingValue: process.env.REACT_APP_BACKEND_URL + '/settings/{settingId}/change-setting-value',
            deleteRedisCache: process.env.REACT_APP_BACKEND_URL + '/administration/settings-api/delete-redis-cache',
        },
        apiKeys: {
            main: process.env.REACT_APP_BACKEND_URL + '/administration/settings-api',
            types: process.env.REACT_APP_BACKEND_URL + '/administration/settings-api/form/types',
            functions: process.env.REACT_APP_BACKEND_URL + '/administration/settings-api/form/functions',
        },
        systemLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/system-logs',
            types: process.env.REACT_APP_BACKEND_URL + '/system-logs/types',
            actions: process.env.REACT_APP_BACKEND_URL + '/system-logs/actions',
        },
        eventLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/event-logs',
            types: process.env.REACT_APP_BACKEND_URL + '/event-logs/types',
            actions: process.env.REACT_APP_BACKEND_URL + '/event-logs/actions',
        },
        emailLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/email-logs',
            types: process.env.REACT_APP_BACKEND_URL + '/email-logs/types',
        },
        charmLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/charm-logs',
        },
        pages: {
            setPrivacyStatement: process.env.REACT_APP_BACKEND_URL + '/update-privacy-statement',
            getActivePrivacyStatement: process.env.REACT_APP_BACKEND_URL + '/active-privacy-statement',
            getActivePrivacyStatementAdmission:
                process.env.REACT_APP_BACKEND_URL + '/active-privacy-statement-admission',
            main: process.env.REACT_APP_BACKEND_URL + '/pages',
            versions: process.env.REACT_APP_BACKEND_URL + '/pages/{pageId}/versions',
            new: process.env.REACT_APP_BACKEND_URL + '/pages',
            delete: (pageId: string) => process.env.REACT_APP_BACKEND_URL + `/pages/${pageId}`,
        },
        selfAssessment: {
            main: process.env.REACT_APP_BACKEND_URL + '/self-assessments',
        },
        langExamReport: {
            main: process.env.REACT_APP_BACKEND_URL + '/administration/language-exam-report',
            completions:
                process.env.REACT_APP_BACKEND_URL +
                '/administration/student-training-level-language-exam-requirement-completions',
            get: process.env.REACT_APP_BACKEND_URL + '/administration/language-exam-report/{id}',
            save: process.env.REACT_APP_BACKEND_URL + '/administration/language-exam-report/{id}',
            export: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/export/language-exam-report/initiate-export',
                check: process.env.REACT_APP_BACKEND_URL + '/export/language-exam-report/check-export/{id}',
                download: process.env.REACT_APP_BACKEND_URL + '/export/language-exam-report/download-export/{id}',
            },
            completionsExport: {
                initiate:
                    process.env.REACT_APP_BACKEND_URL +
                    '/administration/student-training-level-language-exam-requirement-completions/list/initiate-export',
                check:
                    process.env.REACT_APP_BACKEND_URL +
                    '/administration/student-training-level-language-exam-requirement-completions/check-export/{id}',
                download:
                    process.env.REACT_APP_BACKEND_URL +
                    '/administration/student-training-level-language-exam-requirement-completions/download-export/{id}',
            },
        },
        approvals: {
            main: process.env.REACT_APP_BACKEND_URL + '/administration/approvals',
            filterData: {
                statuses: process.env.REACT_APP_BACKEND_URL + '/administration/approvals/filter-helpers/statuses',
                types: process.env.REACT_APP_BACKEND_URL + '/administration/approvals/filter-helpers/types',
            },
            get: process.env.REACT_APP_BACKEND_URL + '/administration/approvals/{id}',
            save: process.env.REACT_APP_BACKEND_URL + '/administration/approvals/{id}',
        },
        accessManagement: {
            table: process.env.REACT_APP_BACKEND_URL + '/access-management/list',
            save: process.env.REACT_APP_BACKEND_URL + '/access-management/save',
        },
        notifications: {
            table: process.env.REACT_APP_BACKEND_URL + '/notifications/list',
            edit: process.env.REACT_APP_BACKEND_URL + '/notifications/events/{eventId}',
            get: process.env.REACT_APP_BACKEND_URL + '/notifications/events/{eventId}',
            activation: process.env.REACT_APP_BACKEND_URL + '/notifications/activation/{eventId}',
            codeTable: process.env.REACT_APP_BACKEND_URL + '/notifications/code-table',
        },
        userNotifications: {
            list: process.env.REACT_APP_BACKEND_URL + '/user-notifications/',
            seen: process.env.REACT_APP_BACKEND_URL + '/user-notifications/seen',
        },
        courseCompletions: {
            main: (id: string | number) =>
                process.env.REACT_APP_BACKEND_URL + '/administration/training-completions/' + id,
            studentSearch: (program: string) =>
                process.env.REACT_APP_BACKEND_URL + `/administration/training-completions/student-search/${program}`,
            campuses: process.env.REACT_APP_BACKEND_URL + '/administration/training-completions/options/campuses',
            courseTypeInfo: (student: string | number, program: string | number) =>
                process.env.REACT_APP_BACKEND_URL +
                `/administration/training-completions/training-program/course-type-detailed/${student}/${program}`,
        },
        document: {
            main: process.env.REACT_APP_BACKEND_URL + '/administration/documents',
            filter: process.env.REACT_APP_BACKEND_URL + '/administration/documents/list',
            create: process.env.REACT_APP_BACKEND_URL + '/administration/documents/create',
            getTrainingPrograms:
                process.env.REACT_APP_BACKEND_URL +
                '/administration/documents/{documentId}/training-level/{trainingLevelId}/training-programs',
        },
        courseClosing: {
            campuses: process.env.REACT_APP_BACKEND_URL + '/administration/program-closing/options/campuses',
            programs: process.env.REACT_APP_BACKEND_URL + '/administration/program-closing/options/programs',
            semesters: (programId: string | number, campusId: string | number) =>
                process.env.REACT_APP_BACKEND_URL +
                `/administration/program-closing/list/${programId}/${campusId}/semesters`,
            students: (programId: string | number, campusId: string | number) =>
                process.env.REACT_APP_BACKEND_URL +
                `/administration/program-closing/list/${programId}/${campusId}/students`,
            closing: (programId: string | number, studentId: string | number) =>
                process.env.REACT_APP_BACKEND_URL +
                `/administration/program-closing/set-completion/${programId}/${studentId}`,
        },
        certificates: {
            membership: {
                list: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/list',
                semesterOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/semesters',
                campusOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/campuses',
                trainingLevelOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/training-levels',
                trainingProgramOptions:
                    process.env.REACT_APP_BACKEND_URL + '/certificates/membership/training-programs',
                collegeYearOptions: process.env.REACT_APP_BACKEND_URL + '/college-year-options',
                export: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/list/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/list/check-export/{id}',
                    download: process.env.REACT_APP_BACKEND_URL + '/certificates/membership/list/download-export/{id}',
                },
            },
            transcript: {
                list: process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/list',
                trainingProgramOptions:
                    process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/training-programs',
                campusOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/campuses',
                collegeYearOptions: process.env.REACT_APP_BACKEND_URL + '/college-year-options',
                export: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/list/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/list/check-export/{id}',
                    download: process.env.REACT_APP_BACKEND_URL + '/certificates/transcript/list/download-export/{id}',
                },
            },
            confirmation: {
                semesterList: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/list/semester',
                programList: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/list/program',
                semesterOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/semesters',
                campusOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/campuses',
                trainingLevelOptions: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/training-levels',
                trainingProgramOptions:
                    process.env.REACT_APP_BACKEND_URL + '/certificates/completion/training-programs',
                preTrainingProgramOptions:
                    process.env.REACT_APP_BACKEND_URL + '/certificates/completion/pre-training-programs',
                collegeYearOptions: process.env.REACT_APP_BACKEND_URL + '/college-year-options',
                export: {
                    initiate: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/list/initiate-export',
                    check: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/list/check-export/{id}',
                    download: process.env.REACT_APP_BACKEND_URL + '/certificates/completion/list/download-export/{id}',
                },
            },
        },
    },
    selfEvaluation: {
        questionnaireTemplate: {
            create: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates',
            update: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}',
            show: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}',
            search: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/search',
            delete: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}',
            list: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates',
            archive: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}/archive',
            unarchive: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}/unarchive',
            duplicate: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/{id}/duplicate',
            preview: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/preview/save',
            getTemplate: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-templates/preview/' + id,
            courseTypes: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-templates/course-types',
            templateOptions: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-template-options',
            templateAndGroupOptions:
                process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-template-and-group-options',
        },
        questionnaireGroup: {
            create: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups/create',
            search: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups/search',
            list: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups',
            update: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups/edit/{id}',
            show: process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups/show/{id}',
            groupOptions: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-template-group-options',
            getTemplate: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + '/self-evaulations/questionnaire-groups/preview/' + id,
        },
        questionnaireProgram: {
            semesters: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-programs/semesters',
            refreshProgramsForSemester:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/semesters/{semesterId}/refresh',
            list:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/semesters/{semesterId}/list',
            listTemplates: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-programs/templates',
            listTemplatesByQuestionnaireProgram:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/{questionnaireProgramId}/templates',
            listTemplatesForFilter:
                process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-programs/templates/for-filter',
            listSummaryTemplatesForFilter:
                process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-programs/summaries/for-filter',
            previewTemplateContent:
                process.env.REACT_APP_BACKEND_URL + '/self-evaluations/questionnaire-programs/templates/{templateId}',
            updateTemplate:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/{programId}/update-template',
            updateStatus:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/{programId}/update-status',
            updateTemplateSummary:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/{programId}/update-template-summary',
            updateStatusSummary:
                process.env.REACT_APP_BACKEND_URL +
                '/self-evaluations/questionnaire-programs/{programId}/update-summary-status',
        },
        report: {
            semesters: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/report/semesters',
            refresh: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/semesters/${id}/refresh-table`,
            filter: (id: string) => process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/semesters/${id}`,
            update: (semesterId: string, studentEvaluationId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/self-evaluations/report/semesters/${semesterId}/update-self-evaluation-conversation/${studentEvaluationId}`,
            pdf: (semesterId: string, rowId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/self-evaluations/report/semesters/${semesterId}/get-completion-row-for-print/${rowId}`,
            preview: (id: string) => process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/read-fill/${id}`,
            fillForm: (id: string) => process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/write-fill/${id}`,
            export: {
                initiate: (semesterId: string) =>
                    process.env.REACT_APP_BACKEND_URL +
                    `/self-evaluations/report/semesters/${semesterId}/initiate-export`,
                check: (semesterId: string, exportId: string) =>
                    process.env.REACT_APP_BACKEND_URL +
                    `/self-evaluations/report/semesters/${semesterId}/check-export/${exportId}`,
                download: (semesterId: string, exportId: string) =>
                    process.env.REACT_APP_BACKEND_URL +
                    `/self-evaluations/report/semesters/${semesterId}/download-export/${exportId}`,
            },
            summary: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/read-fill-summary/${id}`,
            previousSummary: (id: string) =>
                process.env.REACT_APP_BACKEND_URL + `/self-evaluations/report/read-fill-previous-summary/${id}`,
            studentSearch: process.env.REACT_APP_BACKEND_URL + '/self-evaluations/report/student-search',
        },
        list: process.env.REACT_APP_BACKEND_URL + '/self-evaluation/{studentId}',
    },
    scholarships: {
        semesters: process.env.REACT_APP_BACKEND_URL + '/scholarship/groups/semesters',
        list: (semesterId: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/${semesterId}/list`,
        createGroup: (semesterId: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/${semesterId}`,
        group: (semesterId: string, groupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/${semesterId}/${groupId}`,
        nameSearch: (semesterId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/${semesterId}/search`,
        archivate: (groupId: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/archivate/${groupId}`,
        delete: (groupId: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/${groupId}`,
        campus: (scholarshipGroupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}/options/campuses`,
        trainingLevel: (scholarshipGroupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}/options/levels`,
        trainingProgram: (scholarshipGroupId: string, trainingLevel?: string) =>
            process.env.REACT_APP_BACKEND_URL +
            `/scholarship/groups/targets/${scholarshipGroupId}/options/programs?trainingLevel=${trainingLevel || '0'}`,
        groupList: (scholarshipGroupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}`,
        deleteGroup: (scholarshipGroupId: string, id: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}/${id}`,
        create: (scholarshipGroupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}`,
        update: (scholarshipGroupId: string, id: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}/${id}`,
        check: (scholarshipGroupId: string) =>
            process.env.REACT_APP_BACKEND_URL + `/scholarship/groups/targets/${scholarshipGroupId}/check`,
        admin: {
            semesters: process.env.REACT_APP_BACKEND_URL + '/scholarship/admin/semesters',
            list: (semesterId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/semesters/${semesterId}`,
            groupList: (scholarshipGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/group-list/${scholarshipGroupId}`,
            import: {
                upload: process.env.REACT_APP_BACKEND_URL + '/scholarship/admin/upload-import',
                check: (id: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/check-import/${id}`,
            },
            export: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/scholarship/admin/initiate-export',
                check: (id: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/check-export/${id}`,
                download: (id: string) =>
                    process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/download-export/${id}`,
            },
            finalization: (scholarshipGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/finalization/${scholarshipGroupId}`,
            reopen: (scholarshipGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/reopen/${scholarshipGroupId}`,
            sumValues: (scholarshipGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/sum-values/${scholarshipGroupId}`,
            saveValues: (scholarshipGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/save-values/${scholarshipGroupId}`,
            schedule: {
                main: (scholarshipGroupId: number | string) =>
                    process.env.REACT_APP_BACKEND_URL + `/scholarship/admin/schedule/${scholarshipGroupId}`,
            },
        },
        report: {
            semesters: process.env.REACT_APP_BACKEND_URL + '/scholarship/report/semesters',
            studentSearch: process.env.REACT_APP_BACKEND_URL + '/scholarship/report/students/search-by-term',
            list: (semesterId: string) => process.env.REACT_APP_BACKEND_URL + `/scholarship/report/${semesterId}/list`,
            schedule: (semesterId: string, studentId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/scholarship/report/${semesterId}/${studentId}/schedule`,
            export: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/scholarship/report/{semesterId}/initiate-export',
                check: process.env.REACT_APP_BACKEND_URL + `/scholarship/report/{semesterId}/check-export/{id}`,
                download: process.env.REACT_APP_BACKEND_URL + `/scholarship/report/{semesterId}/download-export/{id}`,
            },
        },
    },
    courseManagement: {
        courseList: {
            main: process.env.REACT_APP_BACKEND_URL + '/courses',
            get: process.env.REACT_APP_BACKEND_URL + '/course',
            coursesClose: process.env.REACT_APP_BACKEND_URL + '/courses/{id}/close',
            coursesOpen: process.env.REACT_APP_BACKEND_URL + '/courses/{id}/open',
            roomCapacity: process.env.REACT_APP_BACKEND_URL + '/courses/rooms/{id}/capacity',
            campuses: process.env.REACT_APP_BACKEND_URL + '/courses/campuses',
            tenantCampuses: process.env.REACT_APP_BACKEND_URL + '/courses/tenant-campuses',
            activeCampuses: process.env.REACT_APP_BACKEND_URL + '/courses/active-campuses',
            buildings: process.env.REACT_APP_BACKEND_URL + '/courses/buildings',
            floors: process.env.REACT_APP_BACKEND_URL + '/courses/floors',
            rooms: process.env.REACT_APP_BACKEND_URL + '/courses/rooms',
            classRooms: process.env.REACT_APP_BACKEND_URL + '/courses/classrooms',
            items: process.env.REACT_APP_BACKEND_URL + '/courses/items',
            semesters: process.env.REACT_APP_BACKEND_URL + '/courses/semesters',
            semestersDetailed: process.env.REACT_APP_BACKEND_URL + '/courses/semesters/detailed',
            courseTypes: process.env.REACT_APP_BACKEND_URL + '/courses/course-types',
            courseManagers: process.env.REACT_APP_BACKEND_URL + '/courses/course-managers',
            userSearch: process.env.REACT_APP_BACKEND_URL + '/course/user-search',
            centralStudentSearch: process.env.REACT_APP_BACKEND_URL + '/course/student-central-search',
            centralStudentSearchByCourse:
                process.env.REACT_APP_BACKEND_URL + '/course/student-central-search-by-course',
            listCategories: process.env.REACT_APP_BACKEND_URL + '/course/categories',
            listCampuses: process.env.REACT_APP_BACKEND_URL + '/course/campuses',
            listCampusesInternal: process.env.REACT_APP_BACKEND_URL + '/course/campuses-internal',
            listCampusesWithoutTenant: process.env.REACT_APP_BACKEND_URL + '/course/campuses-without-tenant',
            listCampusesLocal: process.env.REACT_APP_BACKEND_URL + '/course/campuses/local',
            listAllCampuses: process.env.REACT_APP_BACKEND_URL + '/course/campuses/all-options',
            listTenants: (courseId: string) => process.env.REACT_APP_BACKEND_URL + `/course/${courseId}/tenants`,
            listTenantsByCourse: (courseId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/course/${courseId}/tenants-by-course`,
            listDepartments: process.env.REACT_APP_BACKEND_URL + '/course/departments',
            listPresents: process.env.REACT_APP_BACKEND_URL + '/course/presents',
            listCommunicationNeed: process.env.REACT_APP_BACKEND_URL + '/course/communication-need',
            listCommunicationItemNeed: process.env.REACT_APP_BACKEND_URL + '/course/communication-item-need',
            listItNeed: process.env.REACT_APP_BACKEND_URL + '/course/it-need',
            listVisualAppearances: process.env.REACT_APP_BACKEND_URL + '/course/visual-appearances',
            listRoomLayout: process.env.REACT_APP_BACKEND_URL + '/course/room-layouts',
            listReceptionTypes: process.env.REACT_APP_BACKEND_URL + '/course/reception-types',
            guestPerformerSearch: process.env.REACT_APP_BACKEND_URL + '/course/guest-performer',
            otherOrganiserSearch: process.env.REACT_APP_BACKEND_URL + '/course/other-organiser',
            coursesByCollegeYear: process.env.REACT_APP_BACKEND_URL + '/student/courses',
            listAllCoursesByTerm: process.env.REACT_APP_BACKEND_URL + '/courses/list-all-courses-by-term',
            coursePresences: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-event-presences/{eventId}',
            validForEvaluating: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/valid-for-evaluating',
            evaluateView: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/students/{studentId}/evaluate-view',
            evaluate: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/students/{studentId}/evaluate',
            evaluationOptions: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/evaluation-types',
            list: process.env.REACT_APP_BACKEND_URL + '/courses/list',
            asPrerequisite: process.env.REACT_APP_BACKEND_URL + '/courses/{tenant}/{prerequisite}/prerequisite',
            export: {
                initiate: process.env.REACT_APP_BACKEND_URL + '/courses/list/initiate-export',
                check: process.env.REACT_APP_BACKEND_URL + '/courses/list/check-export/{id}',
                download: process.env.REACT_APP_BACKEND_URL + '/courses/list/download-export/{id}',
            },
        },
        courseBaseData: {
            create: process.env.REACT_APP_BACKEND_URL + '/courses/base-data',
            copy: process.env.REACT_APP_BACKEND_URL + '/courses/{id}/copy',
            get: process.env.REACT_APP_BACKEND_URL + '/courses/base-data/{id}',
            copyFrom: process.env.REACT_APP_BACKEND_URL + '/courses/base-data/copy-from/{id}',
            update: process.env.REACT_APP_BACKEND_URL + '/courses/base-data/{id}',
            getCodeVersion: process.env.REACT_APP_BACKEND_URL + '/courses/get-course-code-version',
            getEvents: process.env.REACT_APP_BACKEND_URL + '/courses/{id}/events',
            searchName: process.env.REACT_APP_BACKEND_URL + '/courses/search-name',
            questionnaireTemplates: (courseId: string) =>
                process.env.REACT_APP_BACKEND_URL + `/courses/${courseId}/questionnaire-templates`,
        },
        courseEvent: {
            list: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/events/',
            create: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/events',
            get: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/events/{id}',
            update: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/events/{id}',
            delete: process.env.REACT_APP_BACKEND_URL + '/course/{courseId}/events/{id}',
            semesterInfo: process.env.REACT_APP_BACKEND_URL + '/courses/semester-info/{courseId}',
            getRoomTaken: process.env.REACT_APP_BACKEND_URL + '/course/events/room-taken',
            getEventAutocomplete: process.env.REACT_APP_BACKEND_URL + '/rest/events/autocomplete-options',
            addEventTar: process.env.REACT_APP_BACKEND_URL + '/rest/events/add',
            remove: process.env.REACT_APP_BACKEND_URL + '/rest/events/remove/{courseId}/events/{eventId}',
        },
        courseTargetAudience: {
            listRequired: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/required',
            listFreeOptional: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/free-optional',
            listRequiredOptional:
                process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/required-optional',
            create: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/',
            put: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/{id}',
            get: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/{id}',
            delete: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-target-audiences/{id}',
            list: process.env.REACT_APP_BACKEND_URL + '/course-target-audiences/{courseId}/list',
            getTenants: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-target-audiences/tenants',
            getLevels:
                process.env.REACT_APP_BACKEND_URL + '/{tenantId}/courses/{courseId}/course-target-audiences/levels',
            getPrograms:
                process.env.REACT_APP_BACKEND_URL + '/{tenantId}/courses/{courseId}/course-target-audiences/programs',
            getYears:
                process.env.REACT_APP_BACKEND_URL + '/{tenantId}/courses/{courseId}/course-target-audiences/years',
            getCampus:
                process.env.REACT_APP_BACKEND_URL + '/{tenantId}/courses/{courseId}/course-target-audiences/campuses',
        },
        documents: {
            list: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/documents',
            create: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/documents',
            update: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/documents/{documentId}',
            get: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/documents/{documentId}',
            delete: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/documents/{documentId}',
        },
        prerequisites: {
            main: process.env.REACT_APP_BACKEND_URL + '/course-prerequisites',
            delete: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-prerequisites/{preRequisiteId}',
            prerequisitesByCourse: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-prerequisites',
            listByCourseRefCode: process.env.REACT_APP_BACKEND_URL + '/courses/list-by-course-ref-code',
        },
        enrollments: {
            delete: process.env.REACT_APP_BACKEND_URL + '/courses/{courseId}/course-enrollments/{enrollmentId}',
            enroll: process.env.REACT_APP_BACKEND_URL + '/course-enrollments/{id}',
        },
        courseEvaluationReport: {
            courses: process.env.REACT_APP_BACKEND_URL + '/course-management/evaluation-report/{semesterId}/courses',
            students: process.env.REACT_APP_BACKEND_URL + '/course-management/evaluation-report/{semesterId}/students',
            studentsSync:
                process.env.REACT_APP_BACKEND_URL + '/course-management/evaluation-report/{semesterId}/students-sync',
            courseSearch:
                process.env.REACT_APP_BACKEND_URL + '/course-management/evaluation-report/{semesterId}/course-search',
            studentSearch:
                process.env.REACT_APP_BACKEND_URL + '/course-management/evaluation-report/{semesterId}/student-search',
            questionnaireFillsCourses:
                process.env.REACT_APP_BACKEND_URL +
                `/course-management/evaluation-report/courses/{courseId}/questionnaire-fills`,
            questionnaireFillsCoursesWithTenant:
                process.env.REACT_APP_BACKEND_URL +
                `/course-management/evaluation-report/{tenantId}/courses/{courseId}/questionnaire-fills`,
            questionnaireFillsStudents:
                process.env.REACT_APP_BACKEND_URL +
                `/course-management/evaluation-report/{semesterId}/students/{studentId}/fills`,
            export: {
                initiateExport:
                    process.env.REACT_APP_BACKEND_URL +
                    `/course-management/evaluation-report/courses/{courseId}/questionnaire-fills/initiate-export`,
                initiateExportWithTenant:
                    process.env.REACT_APP_BACKEND_URL +
                    `/course-management/evaluation-report/{tenantId}/courses/{courseId}/questionnaire-fills/initiate-export`,
                checkExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/courses/{courseId}/questionnaire-fills/check-export/{id}',
                downloadExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/courses/{courseId}/questionnaire-fills/download-export/{id}',
                initiateBySemesterExport:
                    process.env.REACT_APP_BACKEND_URL +
                    `/course-management/evaluation-report/{semesterId}/initiate-export`,
                checkBySemesterExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/{semesterId}/check-export/{id}?exportType={exportType}',
                downloadBySemesterExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/{semesterId}/download-export/{id}?exportType={exportType}',
                initiateCourseCompletionExport:
                    process.env.REACT_APP_BACKEND_URL +
                    `/course-management/evaluation-report/{semesterId}/course-type/initiate-export`,
                checkCourseCompletionExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/{semesterId}/course-type/check-export/{id}',
                downloadCourseCompletionExport:
                    process.env.REACT_APP_BACKEND_URL +
                    '/course-management/evaluation-report/{semesterId}/course-type/download-export/{id}',
            },
            questionnaireFillsByCourseAndStudent: (courseId: string, studentId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/course-management/evaluation-report/course/${courseId}/${studentId}/preview`,
        },
    },
    fileManagement: {
        fileUpload: process.env.REACT_APP_BACKEND_URL + '/file-upload',
        downloadTemp: process.env.REACT_APP_BACKEND_URL + '/temp-download/{type}',
        download: process.env.REACT_APP_BACKEND_URL + '/file-download',
    },
    finance: {
        financialApprovals: {
            list: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals',
            listGuest: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals-guest',
            update: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/update/{id}',
            timeline: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/{id}/timeline',
            updateMany: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/update-many',
            semesters: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/get/semesters',
            initiateExport: process.env.REACT_APP_BACKEND_URL + `/finance/financial-approvals/initiate-export`,
            checkExport: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/check-export/{id}',
            downloadExport: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/download-export/{id}',
            initiateExportGuest:
                process.env.REACT_APP_BACKEND_URL + `/finance/financial-approvals-guest/initiate-export`,
            checkExportGuest:
                process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals-guest/check-export/{id}',
            downloadExportGuest:
                process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals-guest/download-export/{id}',
            downloadInvoice: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/download-invoice',
            downloadInvoiceStudent:
                process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/download-invoice-student',
            refreshCharmData: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/refresh-charm-data',
            checkCharmRequest:
                process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/check-charm-request/{id}',
            updateOrDeleteMany: process.env.REACT_APP_BACKEND_URL + '/finance/financial-approvals/update-many',
        },
        studentFeeProcesses: {
            list: process.env.REACT_APP_BACKEND_URL + '/finance/student-fee-processes',
            listGuest: process.env.REACT_APP_BACKEND_URL + '/finance/student-fee-processes-guest',
            semesters: process.env.REACT_APP_BACKEND_URL + '/finance/student-fee-processes/get/semesters',
        },
    },
    admission: {
        administration: {
            list: process.env.REACT_APP_BACKEND_URL + '/admission/administration/semesters/{semesterId}/list',
            semesterOptions: process.env.REACT_APP_BACKEND_URL + '/admission/administration/semester-options',
            campusOptions: process.env.REACT_APP_BACKEND_URL + '/admission/administration/campus-options',
            trainingLevelOptions:
                process.env.REACT_APP_BACKEND_URL + '/admission/administration/training-level-options',
            trainingProgramOptions:
                process.env.REACT_APP_BACKEND_URL + '/admission/administration/training-programs-options',
            statusChange: process.env.REACT_APP_BACKEND_URL + '/admission/administration/{admissionId}/status',
            deleteGroup: (admissionId: string, admissionGroupId: string) =>
                process.env.REACT_APP_BACKEND_URL +
                `/admission/administration/${admissionId}/${admissionGroupId}/delete`,
        },
        admissionDocument: {
            admissionForm: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}',
            questionnaireTemplates: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}/questionnaires',
            privacyStatements: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}/privacy-statements',
            fileUpload: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}/upload-document',
            fileDownload: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}/download-document/{documentId}',
        },
        admissionGroup: {
            getAdmission: process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}',
            create: process.env.REACT_APP_BACKEND_URL + '/admission/group/create',
            update: process.env.REACT_APP_BACKEND_URL + '/admission/group/update/{groupId}',
        },
        admissionPointTable: {
            pointTable: process.env.REACT_APP_BACKEND_URL + '/admission/{admissionId}/point-table',
        },
        fields: process.env.REACT_APP_BACKEND_URL + '/admission/{semesterId}/fields',
        admissionGroupElement: {
            list: process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/list',
            campusOptions: process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/campus-options',
            trainingLevelOptions:
                process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/training-level-options',
            trainingProgramOptions:
                process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/training-programs-options',
            create: process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/create',
            delete: process.env.REACT_APP_BACKEND_URL + '/admission/group/{groupId}/element/delete/{elementId}',
        },
        export: {
            initiate: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/export/initiate-export',
            check: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/export/check-export/{id}',
            download: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/export/download-export/{id}',
            initiateApplicant:
                process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/export/initiate-applicant-export',
            checkApplicant:
                process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/export/check-applicant-export/{id}',
            downloadApplicant:
                process.env.REACT_APP_BACKEND_URL +
                '/admission/evaluation-report/export/download-applicant-export/{id}',
        },
        period: process.env.REACT_APP_BACKEND_URL + '/admission/period',
        generateToken: process.env.REACT_APP_BACKEND_URL + '/admission/generate-token',
        checkMccToken: process.env.REACT_APP_BACKEND_URL + '/admission/check-token/me',
        checkExternalStudent: process.env.REACT_APP_BACKEND_URL + '/admission/check-external-student',
        checkToken: process.env.REACT_APP_BACKEND_URL + '/admission/check-token/{token}',
        saveBaseData: process.env.REACT_APP_BACKEND_URL + '/admission/save-basedata/{token}',
        admissionListMcc: process.env.REACT_APP_BACKEND_URL + '/admission/list/me',
        admissionListToken: process.env.REACT_APP_BACKEND_URL + '/admission/list/{token}',
        listCampuses: process.env.REACT_APP_BACKEND_URL + '/admission/campuses',
        listPrograms: process.env.REACT_APP_BACKEND_URL + '/admission/programs?campus={campus}',
        getProgramToken: process.env.REACT_APP_BACKEND_URL + '/admission/save-program/{token}/{serial}',
        saveProgramToken: process.env.REACT_APP_BACKEND_URL + '/admission/save-program/{token}/{serial}',
        removeProgramToken: process.env.REACT_APP_BACKEND_URL + '/admission/remove-program/{token}/{serial}',
        getProgramMcc: process.env.REACT_APP_BACKEND_URL + '/admission/save-program/me/{serial}',
        saveProgramMcc: process.env.REACT_APP_BACKEND_URL + '/admission/save-program/me/{serial}',
        removeProgramMcc: process.env.REACT_APP_BACKEND_URL + '/admission/remove-program/me/{serial}',
        getQuestionnaireToken: (token: string, serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/${token}/${serial}`,
        saveQuestionnaireToken: (token: string, serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/${token}/${serial}`,
        getQuestionnaireMcc: (serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/me/${serial}`,
        saveQuestionnaireMcc: (serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/me/${serial}`,
        getDocuments: (token: string, serial: string | undefined) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/${token}/${serial}`,
        uploadDocument: (token: string, serial: number | null, admission_document_id: number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/${token}/${serial}/${admission_document_id}`,
        downloadDocument: (token: string, serial: number | null, admission_document_id: number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/${token}/${serial}/${admission_document_id}`,
        getDocumentsMcc: (serial: number | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/me/${serial}`,
        uploadDocumentMcc: (serial: number | null, admission_document_id: number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/me/${serial}/${admission_document_id}`,
        downloadDocumentMcc: (serial: number | null, admission_document_id: number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/me/${serial}/${admission_document_id}`,
        getSemesterOptions: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/semester-options',
        getApplicants: (id: string | number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/evaluation-report/applicants/${id}`,
        previewMccQuestionnaire: (serial: string | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/me/${serial}/show`,
        previewTokenQuestionnaire: (serial: string, token: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/questionnaire/${token}/${serial}/show`,
        getCampusOptions: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/campus-options',
        getTrainingLevelOptions:
            process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/training-level-options',
        getTrainingProgramsOptions:
            process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/training-programs-options',
        getStatusOptions: process.env.REACT_APP_BACKEND_URL + '/admission/evaluation-report/status-options',
        applicantSearch: process.env.REACT_APP_BACKEND_URL + '/admission/applicant-report/applicants',
        applicantProgramList: (email: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/applicant-report/list/${email}`,
        getApplicantBaseData: (semesterId: number, admissionApplicantId: number | null) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/base-data/${admissionApplicantId}`,
        getStatus: (semesterId: number, admissionApplicantId: number, admissionApplicantProgramid: number) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/status/${admissionApplicantId}/${admissionApplicantProgramid}`,
        changeStatus: (semesterId: number, admissionApplicantId: number, admissionApplicantProgramid: number) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/status/${admissionApplicantId}/${admissionApplicantProgramid}`,
        getApplicantDocuments: (
            semesterId: number,
            admissionApplicantId: number,
            admissionApplicantProgramid: number,
        ) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/documents/${admissionApplicantId}/${admissionApplicantProgramid}`,
        downloadApplicantDocument: (
            semesterId: number,
            admissionApplicantId: number,
            admissionApplicantProgramid: number,
            documentId: number,
        ) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/documents/${admissionApplicantId}/${admissionApplicantProgramid}/${documentId}`,
        previewApplicantProgramQuestionnaire: (admissionApplicantProgramId: string) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/applicant-report/admission-form/${admissionApplicantProgramId}`,
        getPoints: (semesterId: number, admissionApplicantId: number, admissionApplicantProgramid: number) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/points/${admissionApplicantId}/${admissionApplicantProgramid}`,
        savePoints: (semesterId: number, admissionApplicantId: number, admissionApplicantProgramid: number) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/points/${admissionApplicantId}/${admissionApplicantProgramid}`,
        guide: process.env.REACT_APP_BACKEND_URL + '/admission/guide',
        settings: process.env.REACT_APP_BACKEND_URL + '/admission/settings',
        finalizeAdmission: (
            semesterId: number,
            admissionApplicantId: number | null,
            admissionApplicantProgramid: number | null,
        ) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/evaluation-report/applicants/${semesterId}/finalize/${admissionApplicantId}/${admissionApplicantProgramid}`,
        moveAdmissionUpMcc: (serial: number | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/change-serial/me/${serial}/up`,
        moveAdmissionDownMcc: (serial: number | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/change-serial/me/${serial}/down`,
        moveAdmissionUp: (token: string, serial: number | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/change-serial/${token}/${serial}/up`,
        moveAdmissionDown: (token: string, serial: number | null) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/change-serial/${token}/${serial}/down`,
        deleteDocument: (admissionToken: string, serial: number | null, admissionDocumentId: number) =>
            process.env.REACT_APP_BACKEND_URL +
            `/admission/documents/${admissionToken}/${serial}/${admissionDocumentId}/delete`,
        deleteDocumentMcc: (serial: number | null, admissionDocumentId: number) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/documents/me/${serial}/${admissionDocumentId}/delete`,
        getPrivacy: (admissionToken: string, serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/list/${admissionToken}/${serial}/privacy`,
        finalize: (admissionToken: string, serial: string) =>
            process.env.REACT_APP_BACKEND_URL + `/admission/list/${admissionToken}/${serial}/finalize`,
    },
};
