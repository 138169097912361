import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ChipBasic, { ChipProps } from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { scoringTypeLabel } from '../../../utils/AppConst';
import { theme } from '../../../utils/theme';
import OperationsCellRenderer from '../OperationsCellRenderer';
import { InfoCardProps } from './types';
import { TypeCellRenderer } from '../TypeCellRenderer';
import HeadCountCellRenderer from '../HeadCountCellRenderer';
import { useContext } from 'react';
import RefreshContext from '../../SelfAssessment/RefreshContextProvider';
import { endpoints } from '../../../utils/endPoints';
import useFetch from '../../../utils/useFetch';

const Chip = (props: ChipProps) => <ChipBasic color="secondary" size="small" variant="outlined" {...props} />;

export default function InfoCard(props: InfoCardProps) {
    const isEnrolledFetch = useFetch<{ enrolled: boolean }>(endpoints.students.courseEnrollment.isEnrolled, '');

    const isEnrolled = props.isEnrolled === 'yes';
    const refresh = useContext(RefreshContext);

    return (
        <Grid item xs={12}>
            <Card
                className="borderRadius"
                style={isEnrolled ? { borderLeft: '5px solid ' + theme.palette.success.main } : {}}
            >
                <CardHeader
                    title={props.courseName}
                    subheader={
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item display="flex" gap="5px">
                                <TypeCellRenderer type={props.type} /> {props.courseCode}{' '}
                                {isEnrolled ? (
                                    <Chip color="success" icon={<CheckCircleIcon />} label="felvett" />
                                ) : null}
                            </Grid>
                            <Grid item>
                                <OperationsCellRenderer
                                    params={{ id: props.id, row: props }}
                                    isEnrolled={!isEnrolledFetch.loading && isEnrolledFetch.data.enrolled}
                                    setRefresh={refresh}
                                />
                            </Grid>
                        </Grid>
                    }
                    titleTypographyProps={{
                        variant: 'body1',
                    }}
                    subheaderTypographyProps={{
                        display: 'flex',
                        gap: '5px',
                    }}
                    sx={{
                        paddingBottom: '4px',
                    }}
                />
                <CardContent
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item>
                            <Chip icon={<SchoolIcon />} label={props.courseType} size="small" />
                        </Grid>
                        <Grid item>
                            <Chip
                                icon={<ListAltIcon />}
                                label={props.maximumAchievablePoint + ' ' + scoringTypeLabel(props.scoringType)}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <HeadCountCellRenderer
                                Opener={(chipProps) => (
                                    <Chip
                                        icon={<PeopleIcon />}
                                        label={props.headCount + ' / ' + props.maxHeadCount}
                                        size="small"
                                        color={props.headCount >= props.maxHeadCount ? 'error' : 'success'}
                                        {...chipProps}
                                    />
                                )}
                                id={props.id}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
