import { TScoringType } from '../../../services/masterData/courses/courseTypes';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { Building, Campus, Floor, IForm, IUser, Room } from '../../../utils/Interfaces/interfaces';

export type courseType = 'conventional' | 'nonConventional';

export interface IEvent {
    id: number;
    eventType: string;
    eventTypeEn: string;
    instructors: string[];
    guestInstructor: string[];
    comment: string;
    startDate: string | Date;
    endDate: string | Date;
    campus: Campus;
    building: Building;
    floor: Floor;
    classRoom: Room;
    location: string | null;
    locations: string | null;
    pointMin: number | null;
    pointMax: number | null;
    room: string;
    userEventDate: string;
    eventDate: string;
    timezone: string | null;
    point: number;
}

export const initialEventForm = {
    id: 0,
    eventType: '',
    instructor: [],
    guestInstructor: [],
    comment: '',
    startDate: '',
    endDate: '',
    campus: '',
    building: '',
    floor: '',
    classRoom: '',
    location: '',
    locations: '',
};

export interface ISemester {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    processName: string;
    active: boolean;
}

export interface IDocument {
    id: number;
    name: string;
    fileName: string;
    filePath: string;
    type?: string;
}

export interface ICoursePrerequisite {
    id: number;
    courseReferenceCode: string;
    courseName: string;
    courseCode: string;
}

export interface ICoursePointView {
    fixCoursePoint: boolean | null;
    fixCoursePointVal: number | null;
    maximumAchievablePoint: number;
    minimumAchievablePoint?: number; //TODO: ezt javítani, hogy csak egyik legyen
    minimumPointToBeAchieved?: number; //TODO: ezt javítani, hogy csak egyik legyen
    customCoursePoint: boolean | null;
    customCoursePointValMin: number | null;
    customCoursePointValMax: number | null;
    fixedCoursePointPerEvent: boolean | null;
    fixedCoursePointPerEventValMin: number | null;
    fixedCoursePointPerEventValPoint: number | null;
    customCoursePointPerEvent: boolean | null;
}

export interface ITotalPoints {
    fixPoints: number;
    customPoints: number;
    fixEventPoints: number;
    customEventPoints: number;
    totalMaxCustomEventPoints: number;
    totalMinCustomEventPoints: number;
    totalPoints: number;
}

export interface ICourseView extends IForm {
    id: number;
    minPresence: number;
    presence: number;
    documents: IDocument[];
    courseType: courseType;
    courseName: string;
    courseNameEnglish: string;
    semester: string;
    campus: string;
    room: string;
    building: string;
    floor: string;
    guestInstructors: string;
    point: ICoursePointView;
    courseManager: string;
    maxAbsence: number;
    absencesSoFar?: number;
    instructors: IUser[];
    minHeadCount: number;
    maxHeadCount: number;
    headCount: number;
    questionnaire: boolean;
    courseCode: string;
    events: IEvent[];
    organiserCampus: string;
    address: string;
    coursePrerequisites: ICoursePrerequisite[];
    profilePictureOfInstructor?: {
        uuidFileName: string;
        origFileName: string;
    };
    profilePictureOfInstructorId?: number | null;
    evaluation: string | null;
    evaluationText: string | null;
    closed?: boolean;
    totalPointsOnCourse: ITotalPoints;
    scoringType: TScoringType;
    enrollmentStart: string;
    enrollmentEnd: string;
    questionnaireIsAnonymous: boolean;
    notCounted: boolean;
    courseTypeCounted: boolean;
    isGuest: boolean;
    customCoursePointPerEvent: number;
}

export interface CourseFilter {
    name: string;
    courseCode: string;
    courseType: string;
    campus: string;
    courseManager: string;
    instructor: string;
    point: string | number;
    headCount: string | number;
    semester: string;
    closed: string;
}

export interface CourseEnrollmentFilter {
    courseId: string;
    firstName: string;
    lastName: string;
    email: string;
    campus: string;
    trainingLevel: string;
    trainingProgram: string;
    collegeYear: string;
}

export interface ILocation {
    location: string;
    lat: number;
    lng: number;
}

export const initialCourseFilterState = (defaultSemester: string): IInfo<CourseFilter> => ({
    filter: {
        semester: defaultSemester ? [defaultSemester] : [],
    },
    sort: {
        name: 'desc',
    },
    page: 1,
    perpage: 25,
    metadata: {},
});

export const initialCourseEnrollmentFilterState = (filterCourse: string): IInfo<CourseEnrollmentFilter> => ({
    filter: {
        course: [filterCourse],
    },
    sort: {},
    page: 1,
    perpage: 25,
    metadata: {},
});
